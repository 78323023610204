import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReferrerGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const referrer = document.referrer;
    const allowedReferrer = environment.ngGeniusURL;

    console.log(allowedReferrer);

    if (referrer.startsWith(allowedReferrer)) {
      console.log("allowed on sucess page");
      return true;
    } else {
      console.log("not allowed on sucess page");
      this.router.navigate(['/auth/login']); 
      return false;
    }
  }
}
