import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ng6-toastr-notifications";
import { AngularWebStorageModule } from "angular-web-storage";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DatePipe } from "@angular/common";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MaterialModule } from "./modules/material/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "../environments/environment";
import { AuthGuard } from "./core/guards/auth.guard";
import { InitialService } from "./core/services/initial.service";
import { ForgotPasswordModalComponent } from "./modules/auth/forgot-password-modal/forgot-password-modal.component";
import { VerificationTypeComponent } from "./modules/auth/signup/verification-type/verification-type.component";
import { AcceptModalComponent } from "./modules/main/store/order/accept-modal/accept-modal.component";
import { StoreOutletmodalComponent } from "./modules/main/store/store/storeOutletModal/storeOutletModal.component";
import { coreUrlList } from "./core/urlList/coreUrlList";
import { SetInterceptorService } from "./core/interceptors/set-interceptor.service";
import { GetInterceptorService } from "./core/interceptors/get-interceptor.service";
import { ImportProductsModalComponent } from "./modules/main/add-properties/products/import-products-modal/import-products-modal.component";
import { SocketService } from "./core/services/socket.service";
import { CoreModule } from "./core/core.module";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from "@angular/material/radio";
import { GoogleMapComponent } from "./new-Modules/auth/pin-location-module/google-map/google-map.component";
import { MainDoorEntranceComponent } from "./new-Modules/auth/pin-location-module/main-door-entrance/main-door-entrance.component";
import { SharedModule } from "./shared/shared.module";
import { ParkingLotEntranceComponent } from "./new-Modules/auth/pin-location-module/parking-lot-entrance/parking-lot-entrance.component";
import { MainRoadEntranceComponent } from "./new-Modules/auth/pin-location-module/main-road-entrance/main-road-entrance.component";
import { MatDividerModule } from "@angular/material/divider";
import { GeoFenceMapComponent } from "./shared/components/geo-fence-map/geo-fence-map.component";
import { AllocateGeofenceComponent } from "./new-Modules/main/configuration/allocate-geofence/allocate-geofence.component";
import { GeofenceMapComponent } from "./new-Modules/main/configuration/geofence-map/geofence-map.component";
import { TransactionSuccessComponent } from './modules/transaction-success/transaction-success.component';
import { TransactionCancelComponent } from './modules/transaction-cancel/transaction-cancel.component';
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatDatepickerModule } from "@angular/material/datepicker";


import {MatIconModule} from '@angular/material/icon';
export function startupServiceFactory(
  InitialService: InitialService
): Function {
  return () => InitialService.load();
}
export function countdownConfigFactory() {
  return { format: `dd:hh:mm:ss` };
}

@NgModule({
  declarations: [
    AppComponent,
    AcceptModalComponent,
    StoreOutletmodalComponent,
    ForgotPasswordModalComponent,
    VerificationTypeComponent,
    ImportProductsModalComponent,
    GoogleMapComponent,
    MainDoorEntranceComponent,
    ParkingLotEntranceComponent,
    MainRoadEntranceComponent,
    GeoFenceMapComponent,
    GeofenceMapComponent,
    TransactionSuccessComponent,
    TransactionCancelComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    AngularWebStorageModule,
    HttpClientModule,
    NgxSpinnerModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    GooglePlaceModule,
    CoreModule,
    MatCardModule,
    MatTabsModule,
    MatTableModule,
    MatDividerModule,
    MatGridListModule,
    MatButtonModule,
    MatRadioModule,

    MaterialModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ["geometry", "places"],
    }),

    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
    }),
  ],
  providers: [
    coreUrlList,
    InitialService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitialService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
    AuthGuard,
    SocketService,
    NgxSpinnerService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AcceptModalComponent,
    StoreOutletmodalComponent,
    ForgotPasswordModalComponent,
    VerificationTypeComponent,
    ImportProductsModalComponent,
  ],
})
export class AppModule {}
