import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { environment } from "../environments/environment";
import {ReferrerGuard} from "./core/guards/referrer.guard"
import {TransactionSuccessComponent} from "./modules/transaction-success/transaction-success.component"
import {TransactionCancelComponent} from "./modules/transaction-cancel/transaction-cancel.component"
const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "auth",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path:"success",
    canActivate: [ReferrerGuard],
    component: TransactionSuccessComponent
  } , 
   {
    path:"cancel",
    canActivate: [ReferrerGuard],
    component: TransactionCancelComponent
  }

  // {
  //   path: "",
  //   redirectTo: "/login",
  //   pathMatch: "full",
  // },
  // {
  //   path: "",
  //   canActivate: [],
  //   loadChildren: () =>
  //     import("./modules/main/main.module").then((m) => m.MainModule),
  // },
  // {
  //   path: "login",
  //   loadChildren: () =>
  //     import("./modules/auth/login/login.module").then((m) => m.LoginModule),
  // },
  // {
  //   path: "signup",
  //   loadChildren: () =>
  //     import("./modules/auth/signup/signup.module").then((m) => m.SignupModule),
  // },
  // {
  //   path: "resetPassword",
  //   component: ResetPasswordComponent,
  // },
  // {
  //   path: "docsUpload",
  //   component: DocsUploadComponent,
  // },
];

const newRoutes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "",
     canActivate: [AuthGuard],
    loadChildren: () =>
      import("./new-Modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "auth",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./new-Modules/auth/auth.module").then((m) => m.AuthModule),
  },
    {
    path:"success",
    canActivate: [ReferrerGuard],
    component: TransactionSuccessComponent
  } , 
   {
    path:"cancel",
    canActivate: [ReferrerGuard],
    component: TransactionCancelComponent
  }
  

  // {
  //   path: "",
  //   redirectTo: "/login",
  //   pathMatch: "full",
  // },
  // {
  //   path: "",
  //   canActivate: [],
  //   loadChildren: () =>
  //     import("./modules/main/main.module").then((m) => m.MainModule),
  // },
  // {
  //   path: "login",
  //   loadChildren: () =>
  //     import("./modules/auth/login/login.module").then((m) => m.LoginModule),
  // },
  // {
  //   path: "signup",
  //   loadChildren: () =>
  //     import("./modules/auth/signup/signup.module").then((m) => m.SignupModule),
  // },
  // {
  //   path: "resetPassword",
  //   component: ResetPasswordComponent,
  // },
  // {
  //   path: "docsUpload",
  //   component: DocsUploadComponent,
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(environment.newUI ? newRoutes : routes, {
    useHash: true,
    scrollPositionRestoration: "enabled",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
