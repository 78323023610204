import { Injectable, Injector } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";
import { InitialService } from "./initial.service";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import { ENTITY_TYPE } from "../interfaces-types/custom-enums";
import { HttpService } from "./http.service";
import { coreUrlList } from "../urlList/coreUrlList";
import { DataSharingService } from "./data-sharing.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  orderStatus: string = "pending";
  title: any;
  incommingOrderDetais : any;
  deliveryTitle: string;
  dropSetting = {
    enableCheckAll: false,
    singleSelection: false,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  confirmToast(text) {
    return Swal.fire({
      title: "Are you sure?",
      html: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }
  baseUrl;
  imageUrl;
  baseUrl2: any;

  updateCustomOffer(minRange , maxRange, inputValue){
    return Swal.fire({
        title: "Your Previous Custom Offer is not in Range.",
        input: "text",
        inputValue: inputValue, // Assuming `this.previousOffer` holds the value
        icon: "warning",
        showCancelButton: true,
        inputValidator: (value: string) => {
          if (!value) {
            return "Enter Custom Offer.";
          }
          const numericValue = parseFloat(value);
          if (numericValue < minRange || numericValue > maxRange) {
            return `Value Should be between Range ${minRange} & ${maxRange}`;
          }
        }
    }).then();
  }




  // Socket Url
  //  socketUrl = "https://apiondemand.orderszds.com";
  socketUrl = environment.socketUrl;


  public userToggel = new BehaviorSubject<any>({});
  public handlestatusChange = new BehaviorSubject<any>({});
  public pendingOrderCount$ = new Subject<any>();
  constructor(
    private spinner: NgxSpinnerService,
    private toaster: ToastrManager,
    private http: HttpClient,
    private initial: InitialService,
    // public api: HttpService,
    private coreUrlList: coreUrlList,
    private injector: Injector

  ) {
    let data = this.initial.getBaseUrl();
    this.baseUrl = data.baseUrl;
    this.imageUrl = data.imageUrl;
  }
  showSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide();
  }
  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }
  successToast(message) {
    this.toaster.successToastr(message, "", {
      maxShown: 1,
    });
  }
  errorToast(message) {
    this.toaster.errorToastr(message);
  }
  toggleStatus(val) {
    this.userToggel.next(val);
  }
  handleStatus(val) {
    this.handlestatusChange.next(val);
  }
  getGreatSettings() {
    return JSON.parse(localStorage.getItem("greatSettings"));
  }
  getAppSettings() {
    return JSON.parse(localStorage.getItem("appSettings"));
  }
  getFromLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key));
  }
  getModuleFromModuleKey(moduleKey) {
    let moduleList = this.getGreatSettings()["appType"];
    let module = moduleList.find((element) => element.moduleKey == moduleKey);
    return module;
  }


  changeTimeInpToMin(startTime, endTime) {
    console.log(startTime, endTime);

    startTime =
      startTime !== ""
        ? Number(startTime.split(":")[0]) * 60 +
        Number(startTime.split(":")[1])
        : 0;
    endTime =
      endTime !== ""
        ? Number(endTime.split(":")[0]) * 60 +
        Number(endTime.split(":")[1])
        : 0;
    return { startTime: startTime, endTime: endTime }
  }


  convertMinToStr(startTime, endTime) {
    if (startTime > 0 || endTime > 0) {
      let newStartTime, newEndTime;

      newStartTime =
        String(Math.floor(startTime / 60)) + ":";
      console.log(startTime);

      newEndTime = String(Math.floor(endTime / 60)) + ":";
      console.log(endTime);
      if (startTime - Number(Math.floor(startTime / 60)) * 60 < 10) {
        startTime =
          newStartTime +
          `0${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      } else {
        startTime =
          newStartTime +
          `${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      }
      if (endTime - Number(Math.floor(endTime / 60)) * 60 < 10) {
        endTime =
          newEndTime +
          `0${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      } else {
        endTime =
          newEndTime +
          `${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      }
    }
    else {
      if (startTime === 0) {
        startTime = "00:00"
      }
      if (endTime === 0) {
        endTime = "00:00"
      }
    }
    return { startTime: startTime, endTime: endTime }
  }


  setTitleByModuleName(moduleName, type) {
    switch (moduleName) {
      case "foodDeliveryApp": {
        this.title = "Cuisine " + type;
        if (type === "store") {
          this.title = "Restaurants";
        }
        if (type === "Product") {
          this.title = "Products";
        }
        break;
      }
      case "homeservice": {
        this.title = "Provider " + type;
        if (type === "store") {
          this.title = "Provider";
        }
        if (type === "Product") {
          this.title = "Service";
        }
        break;
      }
      case "pharmacy": {
        this.title = "Pharmacy " + type;
        if (type === "store") {
          this.title = "Pharmacy";
        }
        if (type === "Product") {
          this.title = "Medicine";
        }
        break;
      }
      default: {
        this.title = "Merchant " + type;
        if (type === "store") {
          this.title = "Merchant";
        }
        if (type === "Product") {
          this.title = "Products";
        }
        break;
      }
    }
    return this.title;
  }
  deleteEmptyKeysFromObject(obj) {
    for (let key in obj) {
      if (obj[key] == null || obj[key] == "") {
        delete obj[key];
      }
    }
    return obj;
  }

  setTitleForDelivery(moduleName) {
    if (moduleName === "homeservice") {
      this.deliveryTitle = "Service";
    } else {
      this.deliveryTitle = "Delivery";
    }
    return this.deliveryTitle;
  }
  async getSuperAdminAppSetting() {
      return this.http.get<any>(`${environment.baseUrl}${this.coreUrlList.getSuperAdminAppSetting}`).pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  async uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    let res = await this.injector.get(HttpService)
      .post(this.coreUrlList.uploadFile, formData)
      .toPromise();
    return res["data"];
  }
  
  async uploadfile(file,profile) {
    let formData = new FormData();
    formData.append("file", file);
    console.log(formData);
    let param=`directory=${profile}`
    let res = await this.injector.get(HttpService)
      .postWithQuery(this.coreUrlList.uploads3File,param ,formData)
      .toPromise();
    return res["data"];
  }

  showSuccesswallet(title:string,message: string): void {
    Swal.fire({
      html: `
        <div style="position: relative; text-align: center;">
          <div style="
            width: 60px; 
            height: 60px; 
            margin: 0 auto; 
            background-color: green; 
            border-radius: 50%; 
            display: flex; 
            align-items: center; 
            justify-content: center;
            color: white; 
            font-size: 24px;">
            &#10003; <!-- Unicode for checkmark -->
          </div>
         <p style="margin-top: 20px; font-size: 20px; color: black">${title}</p>
          <p style="margin-top: 20px; font-size: 16px; color: #333;">${message}</p>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: 
      '<span style="font-size: 18px; color: white; cursor: pointer; background-color: red;border-radius: 67%;width: 23px;height: 23px;">&times;</span>',
    });
  }

  showDepositwallet(title: string, message: string, message1: string): void {
    Swal.fire({
        html: `
            <div style="position: relative; text-align: center;">
                <!-- Letter Icon -->
                <div style="margin-bottom: 20px;">
                    <i class="fa fa-envelope" style="font-size: 40px; color: #555;"></i>
                </div>
                
                <!-- Title -->
                <p style="margin-top: 10px; font-size: 20px; color: black">${title}</p>
                
                <!-- Messages -->
                <p style="margin-top: 20px; font-size: 16px; color: #333;">${message}</p>
                <p style="margin-top: 20px; font-size: 16px; color: #333;">${message1}</p>
            </div>
        `,
        showConfirmButton: true, // Enable confirm button
        confirmButtonText: 'HOME', // Set the text for the confirm button
        confirmButtonColor: 'red',
        showCloseButton: true,
        closeButtonHtml: 
        '<span style="font-size: 18px; color: white; cursor: pointer; background-color: red; border-radius: 67%; width: 23px; height: 23px;">&times;</span>',
    });
}

}

