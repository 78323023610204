import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MaterialModule } from "src/app/modules/material/material.module";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IconSummaryCardComponent } from "./components/icon-summary-card/icon-summary-card.component";
import { AuthDisplayComponent } from "./components/auth-layout/auth-display/auth-display.component";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { ImageUploadDialogComponent } from "./components/image-upload-dialog/image-upload-dialog.component";
import { ProfileMobileScreenComponent } from "./components/profile-mobile-screen/profile-mobile-screen.component";
import { CustomChipsComponent } from "./components/custom-chips/custom-chips.component";
import { MobileScreenTimePickerComponent } from "./components/mobile-screen-timePicker/mobile-screen-timePicker.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CustomAccordionComponent } from "./components/custom-accordion/custom-accordion.component";
import { MainLayoutComponent } from "./components/main-layout/main-layout.component";
import { FooterSliderComponent } from "./components/footer-slider/footer-slider.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { EventModalComponent } from "./components/event-modal/event-modal.component";
import { TimePickerComponent } from "./components/time-picker/time-picker.component";
import { SwiperModule } from "swiper/angular";
import { GeoFenceMapComponent } from "./components/geo-fence-map/geo-fence-map.component";
import { MatSelectModule } from "@angular/material/select";
import { CustomCheckboxComponent } from "./components/custom-checkbox/custom-checkbox.component";
import { CustomSelectComponent } from "./components/custom-select/custom-select.component";
import { CustomSelectionListComponent } from "./components/custom-selection-list/custom-selection-list.component";
import { CustomProfileComponent } from "./components/custom-profile/custom-profile.component";
import { CustomInputFieldComponent } from "./components/custom-input-field/custom-input-field.component";
import { FiltersPopupComponent } from "./components/filters-popup/filters-popup.component";
// import { QrComponentComponent } from './components/qr-component/qr-component.component’;
import { AgmCoreModule } from "@agm/core";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmDirectionModule } from "agm-direction";
import { environment } from "src/environments/environment";

// import { QrComponentComponent } from './components/qr-component/qr-component.component’;
//import { OrderDetailsComponent } from './components/order-details/order-details.component';
//import { QrComponentComponent } from './components/qr-component/qr-component.component’;
import {MatIconModule} from '@angular/material/icon';
import { DragableOrderListComponent } from './components/dragable-order-list/dragable-order-list.component';
import { VerifyOtpComponent } from "../modules/auth/signup/verify-otp/verify-otp.component";
import { NgOtpInputModule } from "ng-otp-input";
@NgModule({
  declarations: [
    VerifyOtpComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    IconSummaryCardComponent,
    AuthDisplayComponent,
    FileUploaderComponent,
    ImageUploadDialogComponent,
    MobileScreenTimePickerComponent,
    CustomAccordionComponent,
    MainLayoutComponent,
    DatePickerComponent,
    EventModalComponent,
    FooterSliderComponent,
    TimePickerComponent,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomSelectionListComponent,
    CustomProfileComponent,
    CustomInputFieldComponent,
    FiltersPopupComponent,
    DragableOrderListComponent,
    // QrComponentComponent
    // GeoFenceMapComponent,
  ],

  imports: [NgOtpInputModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    SwiperModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ["geometry", "places"],
    }),
    MatIconModule,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    IconSummaryCardComponent,
    AuthDisplayComponent,
    FileUploaderComponent,
    MobileScreenTimePickerComponent,
    CustomAccordionComponent,
    MainLayoutComponent,
    DatePickerComponent,
    EventModalComponent,
    TimePickerComponent,
    CustomCheckboxComponent,
    CustomSelectComponent,
    CustomSelectionListComponent,
    CustomProfileComponent,
    CustomInputFieldComponent,
    FiltersPopupComponent,
    // GeoFenceMapComponent,
    DragableOrderListComponent,
    VerifyOtpComponent
  ],
})
export class SharedModule {}
