import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  private walletDataSubject = new BehaviorSubject<any>(null);

  tempPageForTransaction: any;
  tempPageForVoucher: any;
  tempPageForHeldTransaction: any;
  tempPageForInventory : any;
  tempPageForSales : any;
  viewOrderTrack = false;
  showOrderDetail = false;

  setWalletData(data: any) {
    this.walletDataSubject.next(data);
  }

  getWalletData() {
    return this.walletDataSubject.asObservable();
  }
}
