import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentGatewayService } from 'src/app/core/services/payment-gateway.service';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-transaction-success',
  templateUrl: './transaction-success.component.html',
  styleUrls: ['./transaction-success.component.scss']
})
export class TransactionSuccessComponent implements OnInit {
  storeDetails: any;

  constructor(private route: ActivatedRoute, private paymentGatewayService: PaymentGatewayService, private router: Router) { }

  ngOnInit(): void {
    this.storeDetails = JSON.parse(localStorage.getItem('storeLogin'));
    console.log("paymentgateway storeDetails",this.storeDetails);

    this.route.queryParams.subscribe(params => {
      const sut = params['sut']; 
      const ref = params['ref'];

      this.paymentGatewayService.paymentSuccessful(sut, ref).subscribe({
        next: response => {
          if(this.storeDetails.codDisabledByAdmin == true && this.storeDetails.walletDisabledByAdmin == true){
            this.router.navigate(['/main/wallet-page']);
          }else{
            this.router.navigate(['/wallet/home/topup']);
          }
        },
        error: error => {
          console.error(error);
          setTimeout(() => {
            if(this.storeDetails.codDisabledByAdmin == true && this.storeDetails.walletDisabledByAdmin == true){
              this.router.navigate(['/main/wallet-page']);
            }else{
              this.router.navigate(['/wallet/home/topup']);
            }
           // this.router.navigate(['/wallet/home/topup']); // Change to your desired error page route
          }, 3000);
          return throwError(error);
        }
      });
    });
  }
}
